import _ from "lodash";
import moment from "moment";
import {
  parseDate,
  pgOpsMapFn,
  toSafeString,
  type ParameterSchedulingDisplay,
  type AppEnvironment,
  getReadableImportParsingRuleValue,
  getOFUnit,
  getOperationStatus,
  areSchedulingFiltersActive,
  filterSchedulingOperations,
  filterSchedulingOFs,
  SchedulingTag,
  isDoneOperation,
} from "@oplit/shared-module";
import {
  CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS,
  CSS_OPERATION_CARD_SELECTED_CLASS,
  CSS_OPERATIONS_CARDS_GROUP_SELECTED_CLASS,
  CUSTOM_COLOR_DROPDOWN_COLOR_PALETTE,
  OF_STATUS,
  SCHEDULING_MAX_DISPLAYED_OPS,
  SIMULATION_STATUS,
  TAG_SEPARATOR,
} from "@/config/constants";
import {
  ParametersColorsCategory,
  ParametersColorsCategoryOption,
  SchedulingOperation,
  Simulation,
  Team,
} from "@/interfaces";
import {getOfOperationList} from "./schedulingAPIFns";
import {n} from "./generalHelpers";
import dsColors from "@/scss/colors.module.scss";

/**
 * returns the text color associated to the background color (@colorName) for a scheduling tag
 */
const getTagsTextColor = (colorName: string): string => {
  const oddColors = ["newLightGrey"];
  /**
   * we use the `noir` color as default text color because its value is the same for the light & dark themes
   * the dsColors variable will return the dark theme values
   */
  if (!colorName || oddColors.includes(colorName)) return dsColors.noir;
  return dsColors[colorName.replace("Light2", "Regular")];
};

const schedulingStatusColorBg = (status: string, params: any = {}) => {
  const {schedulingFilter = false} = params;

  switch (status) {
    case "pof":
      return "newPrimaryLight2";
    case "done":
      return "newDisableText";
    case "available":
      return "newGreenLight2";
    case "pending":
    case "operation":
      return "newPurpleLight2";
    case "on-going":
      return "newOrangeLight2";
    default: {
      return schedulingFilter ? "newAppBackground" : "newPurpleLight2";
    }
  }
};
const schedulingStatusColorText = (status: string, params: any = {}) => {
  const {schedulingFilter = false} = params;

  switch (status) {
    case "pof":
      return "newPrimaryRegular";
    case "done":
      return "blanc";
    case "available":
      return "newGreenDark1";
    case "pending":
    case "operation":
      return "newPurpleDark1";
    case "on-going":
      return "newOrangeDark1";
    default: {
      return schedulingFilter ? "newSubText" : "newPurpleDark1";
    }
  }
};

const priorityColorsBG = (priority: string): string => {
  let result: string;
  const upperPriority = (priority + "").toUpperCase();
  switch (upperPriority) {
    case "P0":
      result = "newPinkDark2";
      break;
    case "P1":
      result = "newOrangeDark2";
      break;
    case "P2":
      result = "newOrangeDark1";
      break;
    case "P3":
      result = "newOrangeRegular";
      break;
    case "P4":
      result = "newOrangeLight1";
      break;
    case "P5":
      result = "newOrangeLight2";
      break;
    case "P6":
      result = "newPrimaryLight2";
      break;
    case "P7":
      result = "newPrimaryLight1";
      break;
    case "P8":
      result = "newPrimaryRegular";
      break;
    case "P9":
      result = "newPrimaryDark1";
      break;
    default:
      result = "";
      break;
  }

  return result;
};

const priorityColorsText = (priority: string): string => {
  let result: string;
  const upperPriority = (priority + "").toUpperCase();
  switch (upperPriority) {
    case "P4":
      result = "newOrangeDark25";
      break;
    case "P5":
      result = "newOrangeDark1";
      break;
    case "P6":
      result = "newPrimaryDark1";
      break;
    default:
      result = "newLayerBackground";
      break;
  }

  return result;
};

const operationMergeSimulationData = (operation: any, simulationItem: any) => {
  const {
    updated_at,
    created_at,
    fast_track,
    quantity_produced,
    quantite,
    id,
    new_date,
    new_status,
    new_fault,
    new_order,
    new_secteur_id,
    smooth_dates,
  } = simulationItem || {};

  operation.fast_track = fast_track ? true : undefined;
  operation.quantity_produced = quantity_produced || 0;

  operation.firestore_id = id;
  operation.new_status = new_status;
  operation.new_fault = new_fault;
  operation.new_order = new_order;

  if (new_order) {
    if (updated_at && typeof updated_at === "string")
      operation.updated_at_time = moment(updated_at).valueOf();
    else if (updated_at?.toDate) {
      operation.updated_at_time = (
        parseDate(updated_at, true) as Date
      ).getTime();
    } else if (!updated_at && created_at?.toDate) {
      operation.updated_at_time = (
        parseDate(created_at, true) as Date
      ).getTime();
    }
  }

  operation.updated = true;
  if (!smooth_dates?.length) {
    if (quantite != null) operation.quantite = quantite || 0;
    operation.new_date = new_date;
    operation.delay = operation.new_date > operation.date?.value;
    operation.new_secteur_id = new_secteur_id;
    operation.secteur_id = new_secteur_id || operation.secteur_id;

    if (new_date || new_secteur_id) {
      const newSecteurId = new_secteur_id || operation.secteur_id;
      const newDate = new_date || operation.date?.value;
      operation.group_by_id = newDate + "_" + newSecteurId;
    }
    return [operation];
  } else {
    if (operation.is_smoothed) return [operation];
    const smoothing_start_min: any = _.minBy(smooth_dates, "selected_date");
    const smoothing_start_date = smoothing_start_min?.selected_date;
    const nb_postes_smoothing = _.uniqBy(smooth_dates, "secteur_id").length;
    const quantite_totale = _.sumBy(smooth_dates, (o: any) => +o.quantite || 0);
    const operations = smooth_dates.map((op: any, i: number) => {
      const {selected_date, secteur_id, quantite} = op;
      const delay = selected_date > operation.date?.value;
      return {
        ...operation,
        quantite,
        quantite_totale,
        new_secteur_id: secteur_id,
        new_date: selected_date,
        delay,
        secteur_id: secteur_id || operation.secteur_id,
        group_by_id: [selected_date, secteur_id].join("_"),
        is_smoothed: true,
        nb_days_smoothing: smooth_dates.length,
        nb_postes_smoothing,
        smoothing_start_date,
        smoothing_position: `${i + 1}/${smooth_dates.length}`,
        smooth_dates,
      };
    });
    return operations;
  }
};

const computedDisplayFn = (
  operation: SchedulingOperation,
  displayConfig: Partial<ParameterSchedulingDisplay>,
  parameters?: {
    theoric_date?: string;
    custom_view?: "calendar_view" | "list_view";
    client_name?: string;
    environment?: AppEnvironment;
  },
): Record<string, string> => {
  const {info1, info2, info3, client_id} = displayConfig;

  // `custom_view` is an additional parameter used for a thinner configuration of the logic below
  const {theoric_date, custom_view} = parameters ?? {};

  const j = function joinString(stringArray: string[], separator = " - ") {
    return stringArray.filter(Boolean).join(separator);
  };

  const mapInfoFields = (fields: string[]) =>
    fields
      .filter(Boolean)
      .map((field: string) =>
        getReadableImportParsingRuleValue<string>(operation[field]),
      );

  const returned: Record<string, string> = {
    header: j(mapInfoFields(info1 || [])),
    prefix: j(mapInfoFields(info2 || [])),
    suffix: j(mapInfoFields(info3 || [])),
  };
  // constructing the `subheader` directly for easier display in components
  returned.subheader = j([returned.prefix, returned.suffix], " | ");

  //Special case for Gantt view, we hijack the settings to always display the same fields
  if (custom_view === "list_view") {
    const hijackedReturn = {
      header: operation.op_name, //Affichage de la carte OP dans la vue Gantt, onglet OFs
      subheader: returned.prefix, //Affichage de la colonne détail dans la vue OFs. La colonne displayed_quantite est directement injectée dans le HTML de GanttRowPrefix
    };
    return hijackedReturn;
  }

  /**
   * special use cases that couldn't be replicated with the parameters scheduling display
   */
  const specialUseCasesClientIDs = [
    "QoGDqp6UoWoRbzsgKasn", // Van Cleef - VCA
    "3Mh1JfaDH8PbpjSRinNS", // Van Cleef - VCA - V2
    "8sr68k1XEp1Vi7iDZ1Hn", // Oplit (internal user)
  ];

  if (!specialUseCasesClientIDs.includes(client_id)) return returned;

  switch (client_id) {
    case "3Mh1JfaDH8PbpjSRinNS":
    case "QoGDqp6UoWoRbzsgKasn": {
      // Van Cleef - VCA
      const vcareturn: Record<string, string> = {
        ...returned,
        suffix: j([returned.suffix, theoric_date]),
      };
      vcareturn.subheader = j([vcareturn.prefix, vcareturn.suffix], " | ");
      return vcareturn;
    }
    case "8GHQKvFXKSd4mHUqDJmB": // Van Cleef - VCA
      return {
        ...returned,
        prefix: j([operation.code_commande, operation.of_name]) || "",
        suffix:
          j([
            operation.op_name,
            operation.initial_date
              ? moment(operation.initial_date).format("DD/MM/YYYY")
              : null,
          ]) || "",
      };
    default:
      return returned;
  }
};

const getArchivedSimulationName = (simulation: Simulation) =>
  `${simulation.name}${TAG_SEPARATOR}validée`;

const mapSimulationsName = (simulationsArray: Simulation[]): Simulation[] =>
  Array.from(
    simulationsArray,
    (simulation: Simulation): Simulation => ({
      ...simulation,
      name:
        simulation.status === "archived"
          ? getArchivedSimulationName(simulation)
          : simulation.name,
    }),
  );

// by design, the piloting tab should only display archived simulations
// this function's aim is to override this logic for specific customers
const pilotingSimulationsFilterCondition = (
  simulation: Simulation,
  parametres: any,
): boolean => {
  return (
    parametres?.unique_scheduling_simulation ||
    simulation.status === SIMULATION_STATUS.ARCHIVED
  );
};

const filterSimulationsOnTeam = (
  simulationsArray: Simulation[],
  team: Team,
): Simulation[] => {
  if (!team?.id) return simulationsArray;
  return simulationsArray.filter(
    (simulation: Simulation): boolean =>
      !simulation.team_ids?.length ||
      (simulation.team_ids || []).includes(team.id),
  );
};

const getOperationProducedAmount = (operation: SchedulingOperation) => {
  if (!operation?.op_id) return;
  const {quantity_produced = 0, quantite_of = 0} = operation;
  if (getOperationStatus(operation) === OF_STATUS.DONE) return +quantite_of;

  return Math.min(+quantity_produced, quantite_of);
};
const getOperationProgressRateItems = (operations: SchedulingOperation[]) => {
  if (!operations?.length) return {produced: 0, qte: 0, unit: null};

  const results = Array.from(operations, getOperationProducedAmount);
  const produced = _.sumBy(results, (o: number) => +o || 0);
  const qte = _.sumBy(
    operations,
    (operation: SchedulingOperation) => +operation.quantite_of,
  );
  const unit = getOFUnit(operations[0]);
  return {produced, qte, unit};
};

const getGanttOperationKey = (
  operation: SchedulingOperation & {isList?: boolean},
): string => {
  return [
    operation.op_id,
    // probably not needed anymore since the removal of smoothed operations
    operation.smooth_idx ?? 0,
    operation.isList
      ? operation.of_id
      : operation.new_secteur_id ?? operation.secteur_id,
  ].join("-");
};

const onClickOutsideSchedulingOperation = function (e) {
  if (!e) return true;

  /**
   * Event properties sent by browsers differ, leading to a need to have multiple conditions below
   * `path` : sent by Chromium browsers (tested Chrome/Brave)
   * `originalTarget` : sent by Firefox
   */
  const {path, originalTarget, target} = e as {
    path?: HTMLElement[];
    originalTarget?: HTMLElement;
    target?: HTMLElement;
  };

  if (path?.length) {
    return !path.some(
      (p: any) =>
        typeof p?.className === "string" &&
        p?.className?.includes(CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS),
    );
  } else if (originalTarget || target) {
    return (
      !(originalTarget || target)?.closest(
        `.${CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS}`,
      ) && (originalTarget || target).closest("#app")
    );
  }

  return true;
};

/**
 * returns an adjusted period based on the window dimensions
 * @period : the period as an array of [startDate, endDate] as moment objects
 * @periodCellWidth : the width of a column
 * @staticOffset : the amount of px to be subtracted from the total window width (static columns, padding..)
 * @isMonthView : if false, each column represents a day otherwise it represents a week (can only be true for the default calendar view)
 * @isWeekendHidden : if true, an offset is added to fill the window (since the filtering of the weekend days happen after the period definition)
 */
const getResponsivePeriod = ({
  period,
  periodCellWidth,
  staticOffset = 0,
  isMonthView = false,
  isWeekendHidden = false,
}: {
  period: [moment.Moment, moment.Moment];
  periodCellWidth: number;
  staticOffset?: number;
  isMonthView?: boolean;
  isWeekendHidden?: boolean;
}): [moment.Moment, moment.Moment] => {
  const windowWidth = window.innerWidth;
  // 68px is the width of the left menu sidebar, which is present for all views inside the app
  const finalOffset = staticOffset + 68;
  // getting the maximum columns that can be displayed
  const maxSubPeriods = Math.floor(
    (windowWidth - finalOffset) / periodCellWidth,
  );
  const [startDate, endDate] = period;
  const subPeriodUnit = isMonthView ? "weeks" : "days";
  // the amount of columns currently displayed
  const subPeriodsInCurrentPeriod =
    moment(endDate).diff(moment(startDate), subPeriodUnit) + 1;

  // only for ganttView, adds working days when we have hidden weekends
  let weekendOffset = 0;
  if (isWeekendHidden) {
    const daysArray = Array.from(
      {length: Math.max(maxSubPeriods, subPeriodsInCurrentPeriod)},
      (_, i: number) => moment(startDate).add(i, subPeriodUnit),
    );
    const weekends = daysArray.filter((date: moment.Moment) =>
      [6, 7].includes(date.isoWeekday()),
    );
    const offset = Math.ceil(weekends.length * (7 / 5)) + 1;
    if (offset >= 6) weekendOffset = offset;
  }

  let additionalSubPeriods =
    maxSubPeriods - subPeriodsInCurrentPeriod + weekendOffset;
  if (additionalSubPeriods <= 0) return period;
  // check we have minimum 7 displayed days on the maille "week"
  if (subPeriodUnit === "days" && additionalSubPeriods < 6)
    additionalSubPeriods = 6;
  return [startDate, endDate.clone().add(additionalSubPeriods, subPeriodUnit)];
};

const isSelectedOperation = (
  selectedOperations: SchedulingOperation[],
  operation: SchedulingOperation,
) => {
  if (!selectedOperations.length) return false;
  return (
    selectedOperations.findIndex(
      (op: SchedulingOperation) =>
        getGanttOperationKey(op) === getGanttOperationKey(operation),
    ) >= 0
  );
};

// FIXME: is this worth doing a mixin ?
const getGanttInterestKey = (isList: boolean) =>
  isList ? "of_id" : "secteur_id";

/**
 * in some cases the `quantite` field of operations has been altered to multiples of 24 for the load rate calculation
 * the holder of the exact operation duration is `op_duration` in such cases
 */
const getOperationDuration = (operation: SchedulingOperation): number =>
  n(operation.op_duration, n(operation.quantite, 0));

const getOperationDurationKey = (operation: SchedulingOperation): string => {
  if ("op_duration" in operation) return "op_duration";
  return "quantite";
};

const getColorCategoryColorName = (
  entity: Record<string, unknown>,
  colorCategory: ParametersColorsCategory,
  schedulingTags?: SchedulingTag[],
): string | undefined => {
  if (!colorCategory) return;
  const {excel_name, options, randomize, oplit_name} = colorCategory;
  const fieldValue = entity[excel_name] as string;
  if (!fieldValue) return;
  if (randomize) {
    /**
     * this is not exact randomization as we need to provide the same color for the same value in different contexts
     */
    const position = hashString(
      fieldValue,
      CUSTOM_COLOR_DROPDOWN_COLOR_PALETTE,
    );
    return CUSTOM_COLOR_DROPDOWN_COLOR_PALETTE[position];
  } else {
    const option = options.find(
      ({name}: ParametersColorsCategoryOption) =>
        name ===
        (oplit_name === "tags"
          ? (schedulingTags || []).find(
              (tag) => tag.id === toSafeString(fieldValue),
            )?.label
          : toSafeString(fieldValue)),
    );
    if (!option) return;
    return option.color_name;
  }
};

const hashString = function (label: string, colorArr: any) {
  let hash = 0;
  for (let i = 0; i < label.length; i++) {
    hash = label.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  const position = Math.abs(hash % colorArr.length);
  return position;
};

const getColorCategoryClass = (
  entity: Record<string, unknown>,
  colorCategory: ParametersColorsCategory,
  schedulingTags?: SchedulingTag[],
): string | undefined => {
  const color = getColorCategoryColorName(
    entity,
    colorCategory,
    schedulingTags,
  );
  if (!color) return;
  return `${color}--colors-categories`;
};

/**
 * @returns the first html node representing the selected group (if existing) or card
 */
const getFirstSelected = (): HTMLElement => {
  const [selectedOperationsFirstGroup] = document.getElementsByClassName(
    CSS_OPERATIONS_CARDS_GROUP_SELECTED_CLASS,
  ) as HTMLCollectionOf<HTMLElement>;

  const [selectedFirstOperation] = document.getElementsByClassName(
    CSS_OPERATION_CARD_SELECTED_CLASS,
  ) as HTMLCollectionOf<HTMLElement>;

  return selectedOperationsFirstGroup || selectedFirstOperation;
};

/**
 * @returns the state of disability or availability for arrows of OperationsActionsMenu
 */
const getArrowsState = (
  selectedArray: string[],
  opsArray: SchedulingOperation[],
  isPastCurrentOperation = false,
): {
  Up?: boolean;
  Down?: boolean;
  Left?: boolean;
} => {
  if (isPastCurrentOperation) {
    return {
      Up: false,
      Down: false,
      Left: false,
    };
  }

  if (!selectedArray?.length || !opsArray?.length) return {};
  const isAnyNotSelected = (array: SchedulingOperation[]): boolean =>
    array.some((item) => !selectedArray.includes(item.op_id));

  return {
    Up: isAnyNotSelected(opsArray.slice(0, selectedArray.length)),
    Down:
      opsArray.length <= SCHEDULING_MAX_DISPLAYED_OPS || // allow down arrow when the calendar is not opened (state's shouldHide)
      isAnyNotSelected(opsArray.slice(selectedArray.length * -1)),
  };
};

/**
 * Calculate the stagnation of an operation
 * @returns the number of days stagned
 */
const operationComputedStagnation = ({
  new_status,
  op_status,
  status_available_at,
  status_ongoing_at,
}: SchedulingOperation): number | null => {
  const status = getOperationStatus({new_status, op_status});
  if (
    !([OF_STATUS.AVAILABLE, OF_STATUS.ON_GOING] as string[]).includes(status) ||
    (!status_available_at && !status_ongoing_at)
  )
    return null;

  const dates: string[] = [];
  if (status_available_at) dates.push(status_available_at);
  if (status_ongoing_at) dates.push(status_ongoing_at);

  const stagnationDate = _.min(dates);

  return moment().diff(stagnationDate, "days");
};

// FIXME: this logic is present in various spots
const getSchedulingOperationExtraInfos = (operation: {
  extra_infos: string | Record<string, unknown>;
}) => {
  if (!operation?.extra_infos) return {};
  const {extra_infos} = operation;
  if (typeof extra_infos === "object") return extra_infos;
  else if (typeof extra_infos === "string") return JSON.parse(extra_infos);
  return {};
};

const getFullOperation = (operation) => ({
  ...getSchedulingOperationExtraInfos(operation),
  ...operation,
});

const hasArrayDoneOperations = (operations: SchedulingOperation[]) => {
  return operations.some(isDoneOperation);
};

export {
  computedDisplayFn,
  schedulingStatusColorText,
  schedulingStatusColorBg,
  operationMergeSimulationData,
  priorityColorsText,
  priorityColorsBG,
  pgOpsMapFn,
  mapSimulationsName,
  filterSimulationsOnTeam,
  pilotingSimulationsFilterCondition,
  getOperationProgressRateItems,
  areSchedulingFiltersActive,
  filterSchedulingOperations,
  filterSchedulingOFs,
  getGanttOperationKey,
  onClickOutsideSchedulingOperation,
  getResponsivePeriod,
  isSelectedOperation,
  getGanttInterestKey,
  getOfOperationList,
  getOperationDuration,
  getOperationDurationKey,
  getTagsTextColor,
  getColorCategoryClass,
  getColorCategoryColorName,
  getFirstSelected,
  getArrowsState,
  operationComputedStagnation,
  getFullOperation,
  getArchivedSimulationName,
  hasArrayDoneOperations,
};
