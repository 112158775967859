import moment from "moment";
import loggerHelper from "@/tscript/loggerHelper";
import {DATE_DEFAULT_FORMAT, datesFromPeriod, Mesh} from "@oplit/shared-module";

const capitalizeWeekdays = (): string[] => {
  return moment
    .weekdays(true)
    .map((day) => `${day.charAt(0).toUpperCase()}${day.slice(1)}`);
};

const dateFormat = (date, format) => {
  const mDate = moment(date);
  if (!mDate.isValid()) {
    loggerHelper.log("Invalid date", date);
    return "";
  }
  return mDate.format(format);
};

const everyHalfHourStrings = (): string[] => {
  const halfHourStrings = [];
  const time = moment("000", "hmm");

  for (let i = 0; i < 48; i++) {
    halfHourStrings.push(
      moment(time)
        .add(30 * i, "minutes")
        .format("HH:mm"),
    );
  }

  return halfHourStrings;
};

// TODO: unit test / commentary
// FIXME: this has been set in /webapp rather than shared-module due to moment locale misconfiguration problems
const getSubperiodsArrayByMesh = (
  period: [string, string],
  mesh: Mesh,
  options?: {
    usePeriodBoundaries?: boolean;
  },
): {startDate: string; endDate: string}[] => {
  const {usePeriodBoundaries = false} = options || {};
  const {startDate, endDate} = datesFromPeriod(period);
  const length = Math.ceil(moment(endDate).diff(startDate, mesh, true)) + 1;

  const subperiodsArray = Array.from({length}, (_, i: number) => {
    const start_date = moment(startDate)
      .add(i, mesh)
      .startOf(mesh)
      .format(DATE_DEFAULT_FORMAT);

    if (start_date > endDate) return;

    const end_date = moment(start_date).endOf(mesh).format(DATE_DEFAULT_FORMAT);

    return {
      startDate: start_date,
      endDate: end_date,
    };
  }).filter(Boolean);

  if (usePeriodBoundaries) {
    subperiodsArray.at(0)!.startDate = startDate;
    subperiodsArray.at(-1)!.endDate = endDate;
  }

  return subperiodsArray as {startDate: string; endDate: string}[];
};

export {
  capitalizeWeekdays,
  dateFormat,
  everyHalfHourStrings,
  getSubperiodsArrayByMesh,
};
