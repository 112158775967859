import {ref, reactive, computed, inject, watch, unref} from "vue";
import {useRoute} from "vue-router";
import {defineStore, storeToRefs} from "pinia";
import _ from "lodash";
import {i18n} from "@/i18n";
import {dbHelper} from "@/tscript/dbHelper/dbBuilder";
import APIClient from "@/api/index";
import {mapSimulationsName} from "@/tscript/utils/schedulingUtils";
import type {
  CustomPeriod,
  Filter,
  MachineCenter,
  OpenSnackbarFunction,
  ParametersColorsCategory,
  Poste,
  SchedulingGroupBy,
  SchedulingUpdate,
  Segment,
  Simulation,
  UnknownError,
} from "@/interfaces";
import {onSnapshot} from "firebase/firestore";
import {useParameterStore} from "./parameterStore";
import {useMainStore} from "./mainStore";
import {useUserStore} from "@/stores/userStore";
import {usePermissionsStore} from "@/stores/permissionsStore";
import {
  ConwipTicketsGauge,
  DATE_WITH_TIME_FORMAT,
  DailyCapa,
  DailyLoadWithSimulationData,
  LocalSchedulingChange,
  LocalSchedulingOp,
  SchedulingColor,
  SchedulingMessage,
  SchedulingTag,
  StoredConwipTicketsGauge,
  asyncForEach,
  localSchedulingOpKeys,
  parseDate,
} from "@oplit/shared-module";
import {SIMULATION_STATUS} from "@/config/constants";
import {SCHEDULING_ROUTES_NAMES_MAPPING} from "@/config/routes";

import moment from "moment";
import uniqid from "uniqid";
import {backOff} from "exponential-backoff";
import loggerHelper from "@/tscript/loggerHelper";
import {getSortedDropdownOptionsList} from "@/tscript/utils/generalHelpers";

const apiClient = new APIClient();

const getSchedulingTags = async (
  payload: Record<string, unknown>,
): Promise<[UnknownError, SchedulingTag[]]> => {
  const whereCondition = Object.keys(payload).map((key: string) => ({
    field: key,
    value: payload[key],
    compare: "==",
  }));

  try {
    const tags: SchedulingTag[] =
      await dbHelper.getAllDataFromCollectionWithAll("scheduling_tags", {
        where: whereCondition,
      });

    return [null, tags];
  } catch (error) {
    return [error, null];
  }
};

type SchedulingChangeParams = {
  simulation_id?: string;
};

export const useSchedulingStore = defineStore("scheduling", () => {
  const openSnackbar = inject<OpenSnackbarFunction>("openSnackbar");
  const segment = inject<Segment>("segment");
  const mainStore = useMainStore();
  const parameterStore = useParameterStore();
  const {currentPermissions} = storeToRefs(usePermissionsStore());
  const {t} = i18n;

  //ORDO
  const schedulingSimulations = ref<Simulation[]>([]); //scheduling_simulations
  const selectedSimulation = ref<Simulation>({}); // selected_simulation
  const schedulingMachineCenters = ref<MachineCenter[]>([]);
  const schedulingSimulationsUpdateData = ref<unknown>({}); // scheduling_simulations_update_data
  const pgOpsModifications = ref<Record<string, any>[]>([]); // pg_ops_modifications
  const dailySchedulingColors = ref<SchedulingColor[]>([]); // daily_scheduling_colors
  const schedulingTags = ref<SchedulingTag[]>([]); // scheduling_tags
  const availableValidatedSimulation = ref<Simulation>(null);
  const messagesByOfId = ref<Record<string, SchedulingMessage[]>>({}); // messages_by_of_id

  /**
   * used to perform updates between distant relative components
   * @type - an arbitrary string used to define the type of the update
   * - currently used : message
   * @field - the field used as the key to find the entity to update (e.g. `of_id`)
   * @value - the value for the field to be compared against
   * e.g.
   * const {field, value} = scheduling_update
   * const myEntity = myArray.find((entity) => entity[field] === value)
   * @payload - the content of the update
   */
  const schedulingUpdate = reactive<SchedulingUpdate>({
    type: null,
    field: null,
    value: null,
    payload: null,
  }); // scheduling_update
  const isOngoingSchedulingUpdate = ref<boolean>(false); // is_ongoing_scheduling_update
  const schedulingCurrentColorCategory = ref<ParametersColorsCategory>(null); // getSchedulingCurrentColorCategory
  const schedulingCurrentGroupBy = ref<SchedulingGroupBy>(null); // getSchedulingCurrentGroupBy

  // Passed down to ExportModal on export with filters enabled
  const calendarOfIdsToExport = ref<Record<string, string[]>>({});
  const pilotingOfIdsToExport = ref<Record<string, string[]>>({});
  const schedulingOfIdsToExport = ref<string[]>([]);

  //this is to manage local changes to the OPs
  const localSchedulingChanges = ref<Record<string, LocalSchedulingChange>>({}); // scheduling changes not sent to server yet
  const localSchedulingParams = ref<SchedulingChangeParams>({}); // scheduling changes not sent to server yet
  const currentlyChangingOfIds = ref<Record<string, boolean>>({}); // of_ids that are currently updating
  const currentlySavingOfIds = ref<Record<string, boolean>>({}); // of_ids that are currently updating
  const savingPromisesIds = ref<Record<string, boolean>>({}); // ids of pending promises
  const schedulingUpdateTrigger = ref(0); //there are watchers on this field to update CalendarRow
  const conwipTicketsGaugeBySectorID = ref<Record<string, ConwipTicketsGauge>>(
    {},
  );
  const isLoadingTotalCapa = ref(false);
  const totalCapaBySector = ref<Record<string, DailyCapa[]>>({});
  const totalCapaByDay = ref<Record<string, number>>({});

  const teamSchedulingSimulations = computed<Simulation[]>(() =>
    mapSimulationsName(schedulingSimulations.value),
  ); // team_scheduling_simulations
  const schedulingMachineCentersAndTags = computed(() => {
    const {mergedParameters} = storeToRefs(mainStore);
    const {scheduling_ignore_machines, scheduling_display_tree} =
      mergedParameters.value;

    return [
      ...(schedulingMachineCenters.value || []).reduce(
        (acc: MachineCenter[], m) => {
          if (!scheduling_display_tree && !m.movex_id) return acc;
          if (m.is_machine && !scheduling_ignore_machines) {
            acc.push({
              ...m,
              id: m.secteur_id,
              name: m.secteur_name,
              text: m.secteur_name,
              parent_text: m.secteur_name,
            });
          } else if (!m.is_machine) acc.push(m);
          return acc;
        },
        [],
      ),
    ];
  }); // scheduling_machine_centers_and_tags
  const schedulingGroupByItems = computed<SchedulingGroupBy[]>(() => {
    const {userData} = storeToRefs(mainStore);
    const {parametersSchedulingColorsCategories} = storeToRefs(parameterStore);
    const {client_id} = userData.value || {};
    const groupeByFiltersList: Filter[] = [
      {
        name: t("scheduling.group_by.client"),
        field: "customer",
      },
      {
        name: t("scheduling.group_by.article"),
        field: "ref_article",
      },
    ];
    if (client_id === "kihJoGUywfHfI7abZWDH") {
      // Parthenay
      groupeByFiltersList.push({
        name: t("scheduling.group_by.article_op"),
        field: "group_by_field",
      });
    } else if (
      ["g0E4nabmVzpLoQWUPnwq", "YYayeSzhWcd4DJOgIKmh"].includes(client_id)
    ) {
      // Lisi SOL
      groupeByFiltersList.push({
        name: t("scheduling.group_by.matiere"),
        field: "matiere",
      });
    } else if (
      ["QoGDqp6UoWoRbzsgKasn", "3Mh1JfaDH8PbpjSRinNS"].includes(client_id)
    ) {
      // Van Cleef
      groupeByFiltersList.push({
        name: t("scheduling.group_by.operation"),
        field: "op_name",
      });
    } else if (client_id === "TT95CMEYHNnR2RpJOUw4") {
      // Lisi VDR
      groupeByFiltersList.push(
        {field: "forme_tete", name: "Forme de tête"},
        {field: "diametre", name: "Diamètre"},
      );
    } else if (client_id === "8GHQKvFXKSd4mHUqDJmB") {
      // Janson
      groupeByFiltersList.push({field: "format", name: "Format"});
    }

    if (!parametersSchedulingColorsCategories.value?.length)
      return getSortedDropdownOptionsList(groupeByFiltersList, "name");

    /**
     * every color category should also represent a group
     * we merge the default group by options with the colors categories of the client,
     * keeping colors categories definition over same field's grouping methods (through the order in the uniqBy first argument)
     */
    const uniqOptions = _.uniqBy(
      [
        ...parametersSchedulingColorsCategories.value.map(
          (colorCategory: ParametersColorsCategory) => ({
            ...colorCategory,
            field: colorCategory.excel_name,
            name: colorCategory.oplit_name,
          }),
        ),
        ...groupeByFiltersList,
      ],
      "field",
    );

    return getSortedDropdownOptionsList(uniqOptions, "name");
  }); // getSchedulingGroupByItems
  const isPiloting = computed<boolean>(() => {
    const route = useRoute();
    if (selectedSimulation.value?.status === SIMULATION_STATUS.ARCHIVED)
      return true;
    const {mergedParameters} = storeToRefs(mainStore);
    if (mergedParameters.value?.unique_scheduling_simulation) return true;
    if (
      route?.name === SCHEDULING_ROUTES_NAMES_MAPPING.piloting &&
      selectedSimulation.value?.status === SIMULATION_STATUS.ARCHIVED
    )
      return true;
    return false;
  });
  const areSchedulingSimulationUpdatesDisabled = computed(
    () => selectedSimulation.value?.status === SIMULATION_STATUS.ARCHIVED,
  );
  const hasLocalChanges = computed(
    () => !!Object.keys(localSchedulingChanges.value).length,
  );
  const isSavingSchedulingChanges = computed(() =>
    Object.values(savingPromisesIds.value).some((x) => !!x),
  );
  /**
   * boolean representing whether or not a user has the ability
   * to update an operation through various methods from different components
   */
  const canOperateOnOperationCards = computed(
    () =>
      currentPermissions.value.scheduling.update_of_date ||
      currentPermissions.value.scheduling.update_of_machine,
  );
  const lastCreatedSimulation = computed<Simulation>(() => {
    return _.maxBy(schedulingSimulations.value, (simu) =>
      parseDate(simu.created_at || simu.updated_at),
    );
  });

  const shouldHandleOPDuration = computed(() => {
    const {clientParameters} = storeToRefs(mainStore);
    return !!clientParameters.value?.gantt_view;
  });
  const selectedConwipGroup = computed(() => {
    const {parametersSectorGroups} = storeToRefs(useParameterStore());
    const {groups} = getUsersSelectedSchedulingSectors(true);

    // we can only select one group that is conwip - therefore the first group of this list is necessarily
    const [group] =
      groups
        .map((groupID) =>
          parametersSectorGroups.value.find(({id}) => id === groupID),
        )
        .filter((group) => group?.is_conwip) || [];

    return group;
  });

  watch(
    () => selectedSimulation.value,
    (val) => {
      const {doesSchedulingSimulationFloat} = storeToRefs(mainStore);
      doesSchedulingSimulationFloat.value =
        val?.id && !val?.import_ids?.some((x: any) => x.collection === "ofs");
    },
    {immediate: true},
  );

  /**
   * on calendar header cells, there is the possibility to define a specific color
   * which is stored inside the `daily_scheduling_colors` firebase table
   * this getters returns the background color related to an object from this table and the associated text color
   */
  const getDailySchedulingColors = (day: string, isPastDay = false): string => {
    const {color_name} =
      dailySchedulingColors.value.find(
        (dailySchedulingColor) => dailySchedulingColor.day_date === day,
      ) ?? {};
    if (!color_name) return "";

    const bgColor = isPastDay
      ? color_name.replace("Regular", "Light1")
      : color_name;

    const textColor = !isPastDay
      ? "white"
      : bgColor === "newDisableText"
      ? "newDisableBG"
      : "newDisableText";

    return `bg-${bgColor} text-${textColor}`;
  };
  const deleteSchedulingTags = async (
    payload: SchedulingTag,
  ): Promise<void> => {
    const {userData} = storeToRefs(mainStore);
    const {id: user_id} = userData.value || {};

    isOngoingSchedulingUpdate.value = true;

    try {
      await dbHelper.setDataToCollection(
        "scheduling_tags",
        payload.id,
        {
          status: "removed",
          removed_by: user_id,
          removed_at: moment.utc().format(DATE_WITH_TIME_FORMAT),
        },
        true,
      );

      schedulingTags.value = [
        ...schedulingTags.value.filter(
          (tag: SchedulingTag) => tag.id !== payload.id,
        ),
      ];
    } catch (error: unknown) {
      // TODO: rework errors logging system
      openSnackbar(null, "GENERIC_ERROR");
    } finally {
      isOngoingSchedulingUpdate.value = false;
    }
  };
  const updateSchedulingTag = async (
    payload: SchedulingTag,
  ): Promise<[UnknownError, SchedulingTag]> => {
    const {userData} = storeToRefs(mainStore);
    const {id: user_id, client_id} = userData.value || {};

    /**
     * we ensure the tag doesn't already exist and wasn't loaded at first
     * this can happen when another user adds a tag with the current label
     * during the session of the user calling this action
     */
    if (!payload.id) {
      const [error, tags] = await getSchedulingTags({
        label: payload.label,
        client_id,
      });

      // an error occurred / a tag with this label already exists
      if (error || tags?.length) {
        const alertConstant = error ? "GENERIC_ERROR" : "NO_DUPLICATES";
        // TODO: rework errors logging system
        openSnackbar(null, alertConstant);
        // refreshing store
        loadSchedulingTags({client_id});

        return [error, null];
      }
    }

    isOngoingSchedulingUpdate.value = true;

    const finalPayload: SchedulingTag = {
      ...payload,
      id: payload.id ?? dbHelper.getCollectionId("scheduling_tags"),
      client_id,
      updated_by: user_id,
      updated_at: moment.utc().format(DATE_WITH_TIME_FORMAT),
    };

    try {
      await dbHelper.setDataToCollection(
        "scheduling_tags",
        finalPayload.id,
        finalPayload,
        true,
      );

      schedulingTags.value = [
        // showing the updated tag at the top of the list
        finalPayload,
        ...schedulingTags.value.filter(
          (tag: SchedulingTag) => tag.id !== finalPayload.id,
        ),
      ];

      return [null, finalPayload];
    } catch (error: unknown) {
      // TODO: rework errors logging system
      openSnackbar(null, "GENERIC_ERROR");
      return [error, null];
    } finally {
      isOngoingSchedulingUpdate.value = false;
    }
  };
  const updateMultipleSchedulingTagsFromIds = async (
    tagIds: string[],
  ): Promise<void> => {
    const newTags = unref(schedulingTags).map((tag) =>
      tagIds.includes(tag.id)
        ? {...tag, last_clicked: moment.utc().format(DATE_WITH_TIME_FORMAT)}
        : tag,
    );
    const tagsToUpdate = newTags.filter(({id}) => tagIds.includes(id));

    await asyncForEach(tagsToUpdate, async ({id, last_clicked}) => {
      await dbHelper.setDataToCollection(
        "scheduling_tags",
        id,
        {last_clicked},
        true,
      );
    });

    schedulingTags.value = newTags;
  };
  const loadSchedulingTags = async (
    payload: Record<string, unknown>,
  ): Promise<void> => {
    const [error, tags] = await getSchedulingTags(payload);
    // TODO: rework errors logging system
    if (error) openSnackbar(null, "GENERIC_ERROR");
    if (tags) schedulingTags.value = tags;
  };
  const updateDailySchedulingColor = async (
    payload: SchedulingColor,
  ): Promise<void> => {
    const {userData} = storeToRefs(mainStore);
    const {day_date, color_name} = payload;

    const {id} =
      dailySchedulingColors.value.find(
        (color) => color.day_date === day_date,
      ) ?? {};

    const nextDailySchedulingColors = dailySchedulingColors.value.filter(
      (color) => color.id !== id,
    );

    // this is the case when we remove a previously added dailySchedulingColor
    if (!color_name) {
      // early return if no color_name defined (should never be the case)
      if (!id) return;

      try {
        await dbHelper.deleteData("daily_scheduling_colors", id);

        dailySchedulingColors.value = nextDailySchedulingColors;
      } catch (error: unknown) {
        // TODO: rework errors logging system
        openSnackbar(null, "GENERIC_ERROR");
      }
      return;
    }

    const finalPayload: SchedulingColor = {
      ...payload,
      id: id ?? (await dbHelper.getCollectionId("daily_scheduling_colors")),
      client_id: userData.value.client_id,
    };

    try {
      await dbHelper.setDataToCollection(
        "daily_scheduling_colors",
        finalPayload.id,
        finalPayload,
        true,
      );

      dailySchedulingColors.value = [
        ...nextDailySchedulingColors,
        finalPayload,
      ];

      segment.value.track("[Ordo] Daily Scheduling Color Added/Updated", {
        is_new: !id,
        date: finalPayload.day_date,
      });
    } catch (error: unknown) {
      // TODO: rework errors logging system
      openSnackbar(null, "GENERIC_ERROR");
    }
  };
  const loadDailySchedulingColors = async (clientId: string): Promise<void> => {
    const {incrementAppValue} = mainStore;
    const schedulingColors = await dbHelper.getAllDataFromCollectionWithAll(
      "daily_scheduling_colors",
      {
        where: [{field: "client_id", value: clientId, compare: "=="}],
      },
    );

    dailySchedulingColors.value = schedulingColors;
    incrementAppValue(10);
  };
  const loadSchedulingSimulations = async (
    clientId: string,
    forceAsAdmin = false,
  ): Promise<void> => {
    const {incrementAppValue, isDevEnv} = mainStore;
    const {isOplitAdmin} = storeToRefs(useUserStore());
    const q = dbHelper.createRef("simulations", {
      where: [
        {field: "client_id", value: clientId, compare: "=="},
        {field: "is_scheduling", value: true, compare: "=="},
      ],
      orderBy: [{field: "created_at", direction: "desc"}],
    });
    let newSchedulingSimulations: Simulation[] = [];
    onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const doc = change.doc;
        const data = doc.data() || {};
        // check on the changes if we have a new validated simulation
        if (data.status === "archived") {
          const localeUpdatedSimu = newSchedulingSimulations.find(
            (simu) => simu.id === data.id,
          );
          if (localeUpdatedSimu && localeUpdatedSimu.status !== "archived")
            availableValidatedSimulation.value = data;
        }
        if ([data.status, change.type].includes("removed")) {
          newSchedulingSimulations = newSchedulingSimulations.filter(
            (schedulingSimulation) => schedulingSimulation.id !== data.id,
          );
        } else if (
          data.only_admin &&
          !(isOplitAdmin.value || forceAsAdmin) &&
          !isDevEnv
        ) {
          //do nothing
        } else if (change.type == "added") newSchedulingSimulations.push(data);
        else {
          newSchedulingSimulations = newSchedulingSimulations.map(
            (schedulingSimulation) =>
              schedulingSimulation.id === data.id ? data : schedulingSimulation,
          );
        }
      });

      newSchedulingSimulations = _.orderBy(
        newSchedulingSimulations,
        (o) => parseDate(o.created_at),
        "desc",
      );
      schedulingSimulations.value = newSchedulingSimulations;
    });

    incrementAppValue(10);
  };
  const loadSchedulingMachineCenters = async (): Promise<void> => {
    const {incrementAppValue} = mainStore;
    const machineCenters: MachineCenter[] = await apiClient.getMachineCenters();

    schedulingMachineCenters.value = machineCenters;

    incrementAppValue(10);
  };
  const schedulingColorCategoryHandler = (payload: {
    parametresUserIdentifier: string;
    colorCategory?: ParametersColorsCategory;
  }): void => {
    if (!payload) return;
    const {userParameters} = storeToRefs(mainStore);
    const {setUserParameter} = mainStore;
    const {parametersSchedulingColorsCategories} = storeToRefs(parameterStore);
    if (!parametersSchedulingColorsCategories.value?.length) return;

    const {parametresUserIdentifier, colorCategory} = payload;

    // if @payload doesn't have this key, we are initializing the state for the current page
    if (Object.hasOwn(payload, "colorCategory")) {
      // saving with `undefined` doesn't seem to be working with firebase  : we pass `null` instead
      setUserParameter({
        [parametresUserIdentifier]: colorCategory?.excel_name ?? null,
      });
      schedulingCurrentColorCategory.value = colorCategory;
    } else {
      // we retrieve the current configuration from the saved `excel_name`
      const storeColorCategory: ParametersColorsCategory =
        parametersSchedulingColorsCategories.value.find(
          ({excel_name}: ParametersColorsCategory) =>
            excel_name === userParameters.value[parametresUserIdentifier],
        );
      schedulingCurrentColorCategory.value = storeColorCategory;
    }
  };
  const schedulingGroupByHandler = (payload: {
    parametresUserIdentifier: string;
    groupBy?: SchedulingGroupBy;
  }): void => {
    if (!payload) return;

    const {parametresUserIdentifier, groupBy} = payload;
    const {userParameters} = storeToRefs(mainStore);
    const {setUserParameter} = mainStore;
    const {parametersSchedulingColorsCategories} = storeToRefs(parameterStore);

    // if @payload doesn't have this key, we are initializing the state for the current page
    if (Object.hasOwn(payload, "groupBy")) {
      // saving with `undefined` doesn't seem to be working with firebase  : we pass `null` instead
      setUserParameter({
        [parametresUserIdentifier]: groupBy?.field ?? null,
      });

      schedulingCurrentGroupBy.value = groupBy;
    } else {
      // we retrieve the current configuration from the saved `field`
      const storeGroupBy: SchedulingGroupBy = schedulingGroupByItems.value.find(
        ({field}: SchedulingGroupBy) =>
          field === userParameters.value[parametresUserIdentifier],
      );

      // this is the case for default grouping methods
      if (!storeGroupBy?.id) {
        schedulingCurrentGroupBy.value = storeGroupBy;
        return;
      }

      /**
       * this is the case when the current group is a color category
       * we retrieve the current state of the color category for the group by assignment
       */
      const storeColorCategory: ParametersColorsCategory =
        parametersSchedulingColorsCategories.value.find(
          ({excel_name}: ParametersColorsCategory) =>
            excel_name === storeGroupBy.field,
        );

      schedulingCurrentGroupBy.value = {
        ...storeGroupBy,
        ...storeColorCategory,
      };
    }
  };
  async function loadSchedulingMessages(clientId: string) {
    try {
      if (!clientId) return;
      const constraints = {
        where: [{field: "client_id", value: clientId, compare: "=="}],
      };

      const q = dbHelper.createRef("scheduling_messages", constraints);
      let allMessages: SchedulingMessage[] = [];
      onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const doc = change.doc;
          const data = (doc.data() || {}) as SchedulingMessage;
          if ([data.status, change.type].includes("removed")) {
            allMessages = allMessages.filter(
              (message) => message.id !== data.id,
            );
          } else if (change.type == "added") allMessages.push(data);
          else {
            allMessages = allMessages.map((message) =>
              message.id === data.id ? data : message,
            );
          }
        });

        allMessages = _.orderBy(allMessages, "timestamp");
        messagesByOfId.value = _.groupBy(allMessages, "of_id");
      });
    } catch (error) {
      openSnackbar({
        message: `Erreur lors de la récupération des messages associés aux OFs : ${error?.message}`,
        type: "negative",
      });
    }
  }

  function addNewSchedulingChanges(
    changes: Record<string, LocalSchedulingChange>,
    params: SchedulingChangeParams,
  ) {
    const {simulation_id} = params;

    //flush previous changes if the params changes
    let shouldFlushChanges = false;
    Object.keys(localSchedulingParams.value).forEach((param: string) => {
      if (localSchedulingParams.value[param] !== params[param])
        shouldFlushChanges = true;
    });
    if (shouldFlushChanges) sendSchedulingChangesToServer();

    localSchedulingParams.value = {simulation_id};

    const updated_at = moment.utc().format("YYYY-MM-DD HH:mm:ss.SSS");
    Object.keys(changes).forEach((op_id: string) => {
      const {initial, update} = changes[op_id]; //the _.groupBy creates an object for each key
      update.updated_at = updated_at; //this'll help us arbitrage conflicts afterwards
      if (!localSchedulingChanges.value[op_id]) {
        //we only pick relevant keys in the "initial" object
        const cleanInitial = _.pick(
          initial,
          ...localSchedulingOpKeys,
        ) as LocalSchedulingOp;
        localSchedulingChanges.value[op_id] = {initial: cleanInitial, update};
        if (initial.of_id) currentlyChangingOfIds.value[initial.of_id] = true;
      } else {
        const {initial: initialState, update: initialUpdate} =
          localSchedulingChanges.value[op_id];
        //we keep the first initial state, and we merge the updates
        localSchedulingChanges.value[op_id] = {
          initial: initialState,
          update: {...initialUpdate, ...update},
        };
      }
    });
    debouncedSaveSchedulingChanges(); //send the changes to be saved after a given delay
  }

  async function sendSchedulingChangesToServer(): Promise<void> {
    //if a saving is still in progress, I debounce the call again
    if (isSavingSchedulingChanges.value)
      return debouncedSaveSchedulingChanges();

    //cancel any previous debounced call to this function
    debouncedSaveSchedulingChanges.cancel();

    //make sure that we have something to save
    if (!hasLocalChanges.value) return;
    if (!localSchedulingParams.value.simulation_id) return;

    const {userData} = storeToRefs(mainStore);
    const {client_id, id, name} = userData.value || {};
    const {simulation_id} = localSchedulingParams.value;
    const promise_id = `${new Date().getTime().toString()}-${uniqid()}`;
    savingPromisesIds.value[promise_id] = true;
    const tempSchedulingChanges = {...localSchedulingChanges.value}; //important to spread here otherwise the value might be overwritten before sending it to the server
    const tempSavingOfIds = {
      ...currentlySavingOfIds.value, // to keep errors status until of_id is changing again
      ...currentlyChangingOfIds.value,
    };
    //keep the of_ids that are currently updating
    currentlySavingOfIds.value = tempSavingOfIds;

    let promiseIdToDelete: string = promise_id,
      results: Awaited<ReturnType<typeof apiClient.pgSaveSchedulingEvents>>;
    //prepare the promise and immediately reset data to avoid sending the same data twice
    try {
      const data = Object.values(tempSchedulingChanges).map(
        ({initial, update}) => ({
          initial,
          update: {
            ...update,
            event_extra_infos: {
              ...(update.event_extra_infos || {}),
              updated_by: id,
            },
          },
        }),
      );
      const savingPromise = backOff(
        //the "backoff" here enables automatic retries in case of failure after a delay that's longer every time
        async () => {
          return await apiClient.pgSaveSchedulingEvents({
            simulation: selectedSimulation.value,
            simulation_id,
            data,
            userData: {client_id, id, name},
            promise_id,
          });
        },
        {
          jitter: "full",
          delayFirstAttempt: false,
          retry: (e: any, attemptNumber: number) => {
            openSnackbar({
              message: `La tentative d'enregistrement numéro ${attemptNumber}/10 a échoué. Nouvelle tentative à venir.`,
              type: "negative",
            });
            return true;
          },
        },
      );

      results = await savingPromise;
      promiseIdToDelete = results?.promise_id || promise_id;
    } catch (e) {
      loggerHelper.log({e});
    } finally {
      handleSavingOfIdsPostAsync(results, {showSuccessSnackbar: true});

      //inform the user that the parsing is finished
      delete savingPromisesIds.value[promiseIdToDelete];
      for (const op_id of Object.keys(tempSchedulingChanges)) {
        if (
          _.isEqual(
            tempSchedulingChanges[op_id],
            localSchedulingChanges.value[op_id],
          )
        ) {
          const opIdToOfId =
            localSchedulingChanges.value[op_id]?.initial?.of_id;
          delete localSchedulingChanges.value[op_id];
          if (opIdToOfId) delete currentlyChangingOfIds.value[opIdToOfId];
        }
      }
    }
  }
  // Create a debounced version of the saving function
  const debouncedSaveSchedulingChanges = _.debounce(
    sendSchedulingChangesToServer,
    5_000,
  ); //keep the changes for 5 seconds before sending them to server

  type SendSchedulingLoadEventToBackendParams = {
    data: {
      initial: LocalSchedulingOp;
      update: Record<string, any>;
    }[];
    promise_id?: string;
    should_skip_daily_load_update?: boolean;
    should_update_all_of?: boolean;
    should_return_updated_values?: boolean;
    should_preload_previous_load?: boolean;
    should_return_insertion_results?: boolean;
    should_force_local_changes?: boolean;
    has_gantt_load_adjustment?: boolean;
    should_handle_op_duration?: boolean;
    has_shift_planning?: boolean;
  };
  async function sendSchedulingLoadEventToBackend(
    params: SendSchedulingLoadEventToBackendParams,
  ) {
    const {userData} = storeToRefs(mainStore);
    const {client_id, id, name} = userData.value || {};

    const {
      data,
      promise_id,
      should_skip_daily_load_update,
      should_update_all_of,
      should_return_updated_values,
      should_preload_previous_load,
      should_return_insertion_results,
      has_gantt_load_adjustment,
      should_handle_op_duration,
      should_force_local_changes,
    } = params;

    const mappedData = data.map(({initial, update}) => ({
      initial,
      update: {
        ...update,
        event_extra_infos: {
          ...(update.event_extra_infos || {}),
          updated_by: unref(userData).id,
        },
      },
    }));

    currentlySavingOfIds.value = data.reduce(
      (acc, {initial}) => ({
        ...acc,
        [initial.of_id]: true,
      }),
      {},
    );

    const results = await apiClient.pgSaveSchedulingEvents({
      simulation: selectedSimulation.value,
      simulation_id: selectedSimulation.value?.id,
      data: mappedData,
      userData: {client_id, id, name},
      promise_id,
      should_skip_daily_load_update,
      should_update_all_of,
      should_return_updated_values,
      should_preload_previous_load,
      should_return_insertion_results,
      should_handle_op_duration,
      should_force_local_changes,
      has_gantt_load_adjustment,
    });

    handleSavingOfIdsPostAsync(results);

    return results;
  }

  function handleSavingOfIdsPostAsync(
    asyncResult?: {
      updated_of_ids: Record<string, boolean>;
    },
    options?: {
      showSuccessSnackbar?: boolean;
    },
  ) {
    const {updated_of_ids = {}} = asyncResult || {};
    const {showSuccessSnackbar = false} = options || {};

    let hasErrors = false;
    let needToUpdate = false;

    Object.keys(currentlySavingOfIds.value).forEach((of_id: string) => {
      if (updated_of_ids[of_id]) {
        delete currentlySavingOfIds.value[of_id];
        needToUpdate = true;
      } else {
        currentlySavingOfIds.value[of_id] = false;
        hasErrors = true;
      }
    });

    if (hasErrors) {
      openSnackbar({
        message: t("scheduling.op_saving_failed"),
        type: "negative",
      });
    } else if (showSuccessSnackbar) openSnackbar(null, "SAVE_SUCCESS");

    if (needToUpdate) schedulingUpdateTrigger.value += 1;
  }

  function setPgOpsModificationsFromUpdate(
    originalOperations: DailyLoadWithSimulationData[],
    updatedOperations?: DailyLoadWithSimulationData[],
  ) {
    pgOpsModifications.value = originalOperations.map((operation) => {
      const match = updatedOperations.find(
        (op) => op.op_id === operation.op_id,
      );
      if (match) return match;
      else return operation;
    });
    return pgOpsModifications.value;
  }

  function getUsersSelectedSchedulingSectors(isOngoingView?: boolean) {
    const {parametersSectorGroups} = storeToRefs(useParameterStore());
    const {userParameters, stations} = storeToRefs(mainStore);

    const userParameterKey = isOngoingView
      ? "scheduling__selected_sectors__on_going"
      : "scheduling__selected_sectors";

    const userParameter = userParameters.value[userParameterKey] as {
      groups: string[];
      sectors: string[];
      toggled: string[];
    };

    if (!userParameter) {
      return {
        groups: [],
        sectors: [],
        toggled: [],
        allSelectedSectorsPopulated: [],
      };
    }

    const populatedSectorsGroupsSectorsIDs = userParameter.groups
      .map(
        (groupID) =>
          parametersSectorGroups.value
            .find(({id}) => id === groupID)
            ?.sectors.map(({secteur_id}) => secteur_id) || [],
      )
      .flat();

    const allSelectedSectorsPopulated = [
      ...populatedSectorsGroupsSectorsIDs,
      ...userParameter.sectors,
    ]
      .map((sectorID) => {
        const populatedSector =
          stations.value.find(({id}) => id === sectorID) ||
          schedulingMachineCentersAndTags.value.find(({id}) => id === sectorID);
        if (!populatedSector) return null;
        return {
          ...populatedSector,
          secteur_name: populatedSector.name,
          secteur_collection: (populatedSector as Poste).collection || "sites",
          secteur_id: populatedSector.id,
        };
      })
      .filter(Boolean);

    return {
      ...userParameter,
      allSelectedSectorsPopulated,
    };
  }

  async function loadSectorConwipTicketsGauges() {
    if (!selectedConwipGroup.value) return;

    const [ticketsGauges] = await apiClient.getConwipTicketsGauges({
      group_id: selectedConwipGroup.value.id,
    });

    if (!ticketsGauges) return;

    conwipTicketsGaugeBySectorID.value = ticketsGauges.reduce(
      (acc, ticketGauge) => ({
        ...acc,
        [ticketGauge.secteur_id]: ticketGauge,
      }),
      {} as Record<ConwipTicketsGauge["secteur_id"], StoredConwipTicketsGauge>,
    );
  }

  function getTotalCapaByDayFromTotalCapaBySector() {
    const allCapaItemsArray = Object.values(totalCapaBySector.value).flat();

    return allCapaItemsArray.reduce((acc, dailyCapa) => {
      if (!dailyCapa) return acc;
      const {day_date, daily_capa} = dailyCapa;
      return {
        ...acc,
        [day_date]: (acc[day_date] || 0) + (+daily_capa || 0),
      };
    }, {});
  }
  async function loadSchedulingSectorsTotalCapa(
    period: CustomPeriod,
    opts?: {
      isOngoingView: boolean;
    },
  ) {
    isLoadingTotalCapa.value = true;

    const {startDate, endDate} = period;
    const {isOngoingView = false} = opts || {};

    const {allSelectedSectorsPopulated} =
      getUsersSelectedSchedulingSectors(isOngoingView);

    const queries = allSelectedSectorsPopulated.map(
      (sector_tree): Promise<DailyCapa[]> => {
        const params = {
          query_type: "daily_total_capa",
          client_id: mainStore.userData.client_id,
          simulation_id: selectedSimulation.value.id,
          startDate,
          endDate,
          sector_tree,
          load_auto_orga: true,
        };

        return mainStore.apiClient.pgCustom(params);
      },
    );

    const dailyTotalCapaBySector = await Promise.all(queries);

    totalCapaBySector.value = dailyTotalCapaBySector.reduce(
      (acc, capaArr, i) => {
        /**
         * for machines, the `secteur_id` returned by the `daily_total_capa` query
         * may not match the `secteur_id` of the considered machine center
         */
        const secteur_id = allSelectedSectorsPopulated[i].id;

        return {
          ...acc,
          [secteur_id]: capaArr,
        };
      },
      {} as Record<string, DailyCapa[]>,
    );

    totalCapaByDay.value = getTotalCapaByDayFromTotalCapaBySector();

    isLoadingTotalCapa.value = false;
  }

  return {
    schedulingSimulations,
    selectedSimulation,
    schedulingMachineCenters,
    schedulingSimulationsUpdateData,
    pgOpsModifications,
    dailySchedulingColors,
    schedulingTags,
    schedulingUpdate,
    isOngoingSchedulingUpdate,
    schedulingCurrentColorCategory,
    schedulingCurrentGroupBy,
    calendarOfIdsToExport,
    pilotingOfIdsToExport,
    schedulingOfIdsToExport,
    teamSchedulingSimulations,
    schedulingMachineCentersAndTags,
    schedulingGroupByItems,
    isPiloting,
    areSchedulingSimulationUpdatesDisabled,
    getDailySchedulingColors,
    deleteSchedulingTags,
    updateSchedulingTag,
    updateMultipleSchedulingTagsFromIds,
    loadSchedulingTags,
    updateDailySchedulingColor,
    loadDailySchedulingColors,
    loadSchedulingSimulations,
    loadSchedulingMachineCenters,
    schedulingColorCategoryHandler,
    schedulingGroupByHandler,
    loadSchedulingMessages,
    availableValidatedSimulation,
    localSchedulingChanges,
    addNewSchedulingChanges,
    savingPromisesIds,
    isSavingSchedulingChanges,
    hasLocalChanges,
    currentlyChangingOfIds,
    currentlySavingOfIds,
    sendSchedulingChangesToServer,
    localSchedulingParams,
    schedulingUpdateTrigger,
    canOperateOnOperationCards,
    sendSchedulingLoadEventToBackend,
    lastCreatedSimulation,
    shouldHandleOPDuration,
    setPgOpsModificationsFromUpdate,
    getUsersSelectedSchedulingSectors,
    loadSectorConwipTicketsGauges,
    conwipTicketsGaugeBySectorID,
    selectedConwipGroup,
    loadSchedulingSectorsTotalCapa,
    totalCapaBySector,
    totalCapaByDay,
    isLoadingTotalCapa,
    messagesByOfId,
  };
});
