import {
  AuthError,
  getAuth,
  OAuthProvider,
  signInWithPopup,
  UserCredential,
} from "firebase/auth";

export async function signInWithMicrosoft(): Promise<
  [UserCredential, null] | [null, AuthError]
> {
  const provider = new OAuthProvider("microsoft.com");
  provider.setCustomParameters({
    tenant: "common",
  });
  const auth = getAuth();
  try {
    const result = await signInWithPopup(auth, provider);
    return [result, null];
  } catch (error) {
    return [null, error];
  }
}
