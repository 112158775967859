import {defineStore, storeToRefs} from "pinia";
import {computed, ref, watch} from "vue";
import {useMainStore} from "@/stores/mainStore";
import {useUserStore} from "@/stores/userStore";
import {useDBUtils} from "@/composables/dbUtils";
import {
  getClientUserRoles,
  getDefaultPermissionsByRole,
  getForcedPermissionsByGroup,
} from "@/tscript/utils/permissions";
import {PERMISSIONS_BY_ROLE_GROUPS} from "@/config/constants";
import {PermissionsByRole} from "@/interfaces";
import {dbHelper} from "@/tscript/dbHelper/dbBuilder";
import {USER_ROLES} from "@oplit/shared-module";

const roles = getClientUserRoles();
const defaultPermissions = Array.from(
  {length: roles.length},
  (_, index: number) => ({
    role: roles[index],
    ...getDefaultPermissionsByRole(roles[index]),
  }),
);

export const usePermissionsStore = defineStore("permissions", () => {
  /**
   * FIXME: this is problematic for usage outside of components before Vue3 integration
   */
  // const openSnackbar = inject<Function>("openSnackbar");
  const mainStore = useMainStore();
  const {isOplitAdmin} = storeToRefs(useUserStore());
  const {
    getBaseVersionedOplitFirestoreDocument,
    getOplitFirestoreDocumentUserMetadata,
  } = useDBUtils();

  const isLoading = ref(false);
  const permissions = ref<Partial<PermissionsByRole>[]>([]);
  const devPermissions = ref<Partial<PermissionsByRole>>(null);
  const permissionsLoadingPromise = ref(null);

  const currentUserPermissions = computed(() => {
    if (devPermissions.value) return devPermissions.value;
    if (isOplitAdmin.value)
      return getDefaultPermissionsByRole(USER_ROLES.ADMIN);
    if (!permissions.value.length) return getDefaultPermissionsByRole();

    const {userData} = storeToRefs(mainStore);
    const defaultUserPermissions = getDefaultPermissionsByRole(
      userData.value?.role,
    );
    const currentUserPermissions = permissions.value.find(
      ({role}) => role === userData.value?.role,
    );
    if (!currentUserPermissions) return defaultUserPermissions;

    /**
     * OPL-6638
     * adds newly-defined permissions that would be absent
     * of existing permissions_by_role documents to associated roles
     */
    return Object.keys(defaultUserPermissions).reduce(
      (acc, permissionGroup) => {
        Object.assign(
          acc[permissionGroup],
          currentUserPermissions[permissionGroup],
        );

        return acc;
      },
      defaultUserPermissions,
    );
  });

  /**
   * these are permissions overridden with specific rules given specific configuration
   */
  const currentPermissions = computed(() => {
    const {clientParameters} = storeToRefs(mainStore);
    const returnedPermissions: Partial<PermissionsByRole> = {};

    for (const groupName of Object.keys(PERMISSIONS_BY_ROLE_GROUPS)) {
      const permissions = currentUserPermissions.value[groupName];
      if (!permissions) continue;
      returnedPermissions[groupName] = Object.assign(
        permissions,
        getForcedPermissionsByGroup(groupName, clientParameters.value),
      );
    }

    return {...currentUserPermissions.value, ...returnedPermissions};
  });

  /**
   * wrapper function for async operations used to retrieve the `isLoading` value in components
   */
  const asyncOperationsProxy =
    <T>(asyncOperation: (payload?: T) => Promise<void>) =>
    async (payload?: T) => {
      isLoading.value = true;
      await asyncOperation(payload);
      isLoading.value = false;
    };

  async function loadPermissionsByRole() {
    const {userData} = storeToRefs(mainStore);
    if (!userData.value?.client_id) return;

    try {
      const clientPermissions = await dbHelper.getAllDataFromCollectionWithAll(
        "permissions_by_role",
        {
          where: [
            {
              field: "client_id",
              value: userData.value.client_id,
              compare: "==",
            },
          ],
        },
      );

      const coalescedPermissions = [
        ...(clientPermissions || []),
        ...defaultPermissions.filter(
          (x) =>
            !clientPermissions?.some(
              (y: PermissionsByRole) => y.role === x.role,
            ),
        ),
      ];

      permissions.value = coalescedPermissions;
    } catch (error) {
      // openSnackbar(null, null, error);
    }
  }

  async function updatePermissionsByRole(
    payload: Partial<PermissionsByRole>[],
  ) {
    try {
      const futureStorePermissions = [];

      for (const permissions of payload) {
        /**
         * for new documents we add a set of mandatory properties for firestore documents
         * otherwise we update the `updated`-prefixed metadata
         */
        Object.assign(
          permissions,
          permissions.id
            ? getOplitFirestoreDocumentUserMetadata(permissions)
            : getBaseVersionedOplitFirestoreDocument("permissions_by_role"),
        );

        await dbHelper.setDataToCollection(
          "permissions_by_role",
          permissions.id,
          permissions,
          true,
        );

        futureStorePermissions.push(permissions);
      }

      permissions.value = futureStorePermissions;

      // openSnackbar(null, "SAVE_SUCCESS");
    } catch (error) {
      // openSnackbar(null, null, error);
    }
  }

  function setDevPermissions(permissions: Partial<PermissionsByRole>) {
    devPermissions.value = permissions;
  }

  /**
   * watches current user's `client_id` and loads associated permissions\
   * is called immediately to populate this store
   */
  watch(
    () => mainStore.userData,
    async (newUserData, oldUserData) => {
      if (!newUserData) return;
      if (newUserData?.client_id === oldUserData?.client_id) return;
      permissionsLoadingPromise.value = loadPermissionsByRole();
    },
    {immediate: true},
  );

  return {
    isLoading,
    permissions,
    loadPermissionsByRole: asyncOperationsProxy(loadPermissionsByRole),
    updatePermissionsByRole: asyncOperationsProxy(updatePermissionsByRole),
    currentPermissions,
    devPermissions,
    setDevPermissions,
    permissionsLoadingPromise,
  };
});
