import {initializeApp} from "firebase/app";
import {initializeFirestore} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDXFgcV0-uC5Rq1ImTHv0wXPP0jqTPYHNg",
  authDomain: "lbast-demo.firebaseapp.com",
  //databaseURL: "https://oplit-staging.firebaseio.com",
  projectId: "lbast-demo",
  storageBucket: "lbast-demo.appspot.com",
  messagingSenderId: "1017187942659",
  appId: "1:1017187942659:web:f8ea1798b9e2614e872936",
  measurementId: "G-W84SJWN8RM"
};

const BACKEND_URL = "https://api-front-hqehzlcjma-ew.a.run.app/";
const BACKEND_IMPORT_URL = "https://api-import-hqehzlcjma-ew.a.run.app/";
const BACKEND_SSE_URL = "https://api-import-hqehzlcjma-ew.a.run.app/";
const BACKEND_DATA_URL = "https://data-api-hqehzlcjma-ew.a.run.app";
const ENABLE_MICROSOFT_SSO = "true" === "true";

const firebaseApp = initializeApp(firebaseConfig);
const firestoreDB = initializeFirestore(firebaseApp, {
  experimentalAutoDetectLongPolling: true,
});

const environment = "demo" as string;

const segment_key = "AqDd3mD0ze3GCr682QyrKIyjQuI0tbNu";

export {
  firebaseConfig,
  BACKEND_URL,
  BACKEND_IMPORT_URL,
  BACKEND_SSE_URL,
  BACKEND_DATA_URL,
  ENABLE_MICROSOFT_SSO,
  environment,
  segment_key,
  firestoreDB
};

export default firebaseApp;
