<template>
  <div>
    <h3
      class="text-left pa-8 pb-2"
      :data-testid="TEST_IDS.SIMULATION_FORM__TITLE"
    >
      {{ formTitle }}
      <span
        v-if="team && !isScheduling"
        data-testid="simulation-form-team-title"
      >
        | {{ $t("Simulation.team") }} {{ team.name }}
      </span>
    </h3>

    <div
      class="v-stepper v-stepper--flat v-stepper--vertical v-sheet bg-transparent"
      :class="{
        'is-last-step': step === 2,
        'theme--dark': theme === 'dark',
        'theme--light': theme === 'light',
      }"
    >
      <!-- simulation main infos title -->
      <div
        @click="handleStepper(1)"
        class="v-stepper__step"
        :class="{
          'v-stepper__step--complete': step > 1,
          'v-stepper__step--active': step === 1,
        }"
      >
        <span class="v-stepper__step__step bg-primary">
          <template v-if="step === 1">1</template>
          <template v-else>
            <i
              aria-hidden="true"
              class="v-icon notranslate mdi mdi-check theme--light"
            ></i>
          </template>
        </span>
        <h4 class="v-stepper__label">
          {{ $t("Simulation.main_information") }}
        </h4>
      </div>
      <!-- simulation main info content -->
      <div class="v-stepper__content stepper-content">
        <div class="v-stepper__wrapper">
          <!-- <v-expand-transition> -->
          <div
            v-if="step === 1 || isOld"
            class="d-flex flex-column gap-8"
            :data-testid="TEST_IDS.SIMULATION_FORM__STEP_ONE_CONTENT"
          >
            <div class="d-flex flex-column gap-4">
              <label class="fbody-1 text-newSubText font-weight-bold">
                {{ $t("Simulation.simulation_name") }}
              </label>
              <v-text-field
                id="new-simulation-name"
                v-model="(currentSimulation || {}).name"
                hide-details
                density="compact"
                :disabled="isReadOnly"
                variant="outlined"
                class="bg-newSubBackground"
                :placeholder="$t('Simulation.december_plan')"
                data-cy="newSimulation-name"
                data-testid="new-simulation-name"
              />
            </div>

            <div class="d-flex flex-column gap-4 mb-6">
              <label
                class="fbody-1 text-newSubText font-weight-bold"
                data-testid="simulation-float-type"
              >
                {{ $t("Simulation.simulation_type") }}
              </label>

              <v-radio-group
                v-if="canSimulationFloat"
                class="radio-group"
                hide-details
                :model-value="isFloatingSimulation"
                :data-testid="`simulation-floating-${shouldSimulationBeFloating}`"
                @update:model-value="handleTypeChange"
              >
                <v-radio
                  :value="false"
                  :ripple="false"
                  :data-testid="TEST_IDS.SIMULATION_FORM__STATIC_INPUT"
                >
                  <template v-slot:label>
                    <vue-feather
                      type="pause"
                      size="16"
                      tag="span"
                      class="mx-3"
                    />
                    <div data-testid="simulation-static-radio">
                      {{ $t("Simulation.simulation_static_desc") }}
                    </div>
                  </template>
                </v-radio>
                <v-radio
                  :value="true"
                  :ripple="false"
                  :data-testid="TEST_IDS.SIMULATION_FORM__FLOATING_INPUT_TRUE"
                >
                  <template v-slot:label>
                    <vue-feather
                      type="refresh-cw"
                      size="16"
                      tag="span"
                      class="mx-3"
                    />
                    <div data-testid="simulation-floating-radio">
                      {{ $t("Simulation.simulation_floating_desc") }}
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>

              <div>
                <div
                  v-if="isFloatingSimulation"
                  class="d-flex align-center fbody-1"
                  data-testid="floating-simulation-desc"
                >
                  <vue-feather
                    type="refresh-cw"
                    size="16"
                    tag="span"
                    class="mr-3"
                  />
                  <div>{{ $t("Simulation.simulation_floating_desc") }}</div>
                </div>
                <div
                  v-else
                  class="d-flex align-center fbody-1"
                  data-testid="static-simulation-desc"
                >
                  <vue-feather type="pause" size="16" tag="span" class="mr-3" />
                  <div>{{ $t("Simulation.simulation_static_desc") }}</div>
                </div>
              </div>
            </div>

            <div v-if="shouldDisplayStartDate" class="d-flex flex-column gap-4">
              <label class="fbody-1 text-newSubText font-weight-bold">
                {{ $t("Simulation.start_date") }}
                {{ isScheduling ? `(${$t("global.optional")})` : "" }}
              </label>
              <div class="datepicker" data-cy="newSimulation-date">
                <FDatePicker
                  btn-display
                  :disabled="isReadOnly"
                  :placeholder="$t('Simulation.enter_a_date')"
                  :date-value="dateText"
                  :fieldValue="currentSimulation?.forceToday"
                  :handle-change="handleDateChange"
                  text-class="flex-1 -ml-8 justify-center"
                  class="w-100"
                  data-testid="newSimulation-datepicker"
                />

                <FCheck
                  v-if="isDevEnv || userData.oplit_bugbug_user"
                  :model-value="currentSimulation.forceToday === '2023-01-01'"
                  data-testid="force-today-checkbox"
                  label="(dev - Bugbug) Forcer la définition de la date de début de la simulation au 01/01/2023"
                  @update:model-value="handleDateChange('2023-01-01')"
                />
              </div>
            </div>
          </div>
          <!-- </v-expand-transition> -->
        </div>
      </div>

      <!-- simulation data title -->
      <div
        v-show="!isFloatingSimulation || !isOld"
        class="v-stepper__step"
        :class="{
          'v-stepper__step--inactive': step < 2,
          'v-stepper__step--active': step === 2,
        }"
        data-testid="simulation-data-selection-step"
        @click="handleStepper(2)"
      >
        <span class="v-stepper__step__step" :class="{'bg-primary': step >= 2}">
          2
        </span>
        <h4 class="v-stepper__label">
          {{ $t("Simulation.data_selection") }}
        </h4>
      </div>
      <!-- simulation data content -->
      <div class="v-stepper__content stepper-content last-step">
        <!-- <v-expand-transition> -->
        <div
          v-if="step === 2 || isOld"
          :data-testid="TEST_IDS.SIMULATION_FORM__STEP_TWO_CONTENT"
        >
          <template v-if="!isFloatingSimulation">
            <v-skeleton-loader
              :loading="isLoadingImports"
              type="text"
              class="pt-2"
              width="100"
              height="24"
              boilerplate
            >
              <div
                v-for="(importType, idx) in importTypes"
                :key="importType"
                :data-cy="`newSimulation-selector-${importType}`"
                class="d-flex flex-column gap-4"
              >
                <label class="fbody-1 text-newSubText font-weight-bold">
                  {{ $t("Simulation." + importType) }}
                </label>

                <div v-if="showEventualToggler(importType)">
                  <FSwitchButton
                    :model-value="importTypesTogglerValues[importType]"
                    :items="importTypesTogglerItems[importType]"
                    @update:model-value="
                      (t) => handleImportTypesTogglerInput(t, importType)
                    "
                    class="flex-1"
                    :data-testid="TEST_IDS.SIMULATION_FORM__TYPE_SWITCH"
                  />
                </div>
                <FDropdown
                  v-model="selectedImports[importType]"
                  :items="getImportTypeSelectItems(importType)"
                  outlined
                  return-object
                >
                  <template v-slot:item="{item}">
                    <v-list-item density="compact" width="100%">
                      <SimulationFormImportItem
                        :item="item"
                        :import-placeholder="importsPlaceholder[importType]"
                        :data-cy="`file-${importType}-${idx}`"
                        :is-floating="isFloatingSimulation"
                      />
                    </v-list-item>
                  </template>
                  <template v-slot:content>
                    <SimulationFormImportItem
                      :item="getSelectedImportItem(importType)"
                      :import-placeholder="importsPlaceholder[importType]"
                      :data-cy="`file-content-${importType}-${idx}`"
                    />
                  </template>
                </FDropdown>
              </div>
            </v-skeleton-loader>
          </template>

          <div v-if="!isOld">
            <FormSimulationsSelect
              :label="$t('Simulation.migrate_events')"
              :simulations="simulationsList"
              @remigrate-events="hacky_remigrateEvents"
              @update-selected-simulation="
                (simulation) => (selectedSimulation = simulation)
              "
            />
          </div>

          <div
            v-if="!isOld && hasMercateam && !isScheduling"
            class="fd-flex-center justify-space-between mt-4"
          >
            <FCheck
              v-model="mercaAbsences"
              id="merca-checkbox"
              :label="$t(`Simulation.load_merca_absences`)"
            />
            <MercateamLogo :height="30" small />
          </div>
        </div>
        <!-- </v-expand-transition> -->
      </div>
    </div>

    <div
      v-if="isDevEnv || isOplitAdmin"
      class="fd-flex-center justify-space-between mb-6 ml-16"
    >
      <FCheck
        v-model="currentSimulation.only_admin"
        id="only_admin-checkbox"
        label="Simulation uniquement accessible aux admins Oplit"
      />
    </div>

    <div class="fd-flex-center justify-end gap-8 pa-8 pt-0">
      <v-btn
        variant="outlined"
        class="no-uppercase fbutton"
        data-testid="close-btn"
        @click="toggleModal"
      >
        {{ isReadOnly ? $t("Simulation.Fermer") : $t("Simulation.Annuler") }}
      </v-btn>

      <v-btn
        :id="step === 1 ? 'simulation-next-btn' : 'simulation-validate-btn'"
        v-if="!isReadOnly"
        variant="outlined"
        :disabled="isDisabledConfirmBtn"
        :class="[
          'no-uppercase fbutton',
          isDisabledConfirmBtn ? 'newSubBackground' : 'newPrimaryRegular btn1',
        ]"
        :loading="isOperationOngoing"
        data-cy="newSimulation-validate"
        :data-testid="TEST_IDS.SIMULATION_FORM__CONFIRM_BUTTON"
        @click="onConfirmBtnClick"
      >
        {{ step === 1 ? $t("Simulation.next") : $t("Simulation.Valider") }}
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref, computed, watch} from "vue";
import {storeToRefs} from "pinia";
import _ from "lodash";
import moment from "moment";
import {
  DATE_DEFAULT_FORMAT,
  parseDate,
  periodeToText,
} from "@oplit/shared-module";
import FDatePicker from "@/components/Global/FDatePicker.vue";
import {FSwitchButton, FCheck, FDropdown} from "@/components/Global/Homemade";
import SimulationFormImportItem from "./SimulationFormImportItem.vue";
import FormSimulationsSelect from "@/views/Simulation/FormSimulationsSelect.vue";
import loggerHelper from "@/tscript/loggerHelper";
import {FORM_SIMULATIONS_SELECT_EMPTY, TEST_IDS} from "@/config/constants";

import {Simulation, Team} from "@/interfaces";
import {useSchedulingStore} from "@/stores/schedulingStore";

import {useMainStore} from "@/stores/mainStore";
import {useI18n} from "vue-i18n";
import {useUserStore} from "@/stores/userStore";
import MercateamLogo from "@/components/Commons/MercateamLogo.vue";
import {getImportTypesFromImports} from "@/tscript/utils/imports";

export default defineComponent({
  name: "simulation-form",
  components: {
    FDatePicker,
    FCheck,
    FSwitchButton,
    FormSimulationsSelect,
    SimulationFormImportItem,
    FDropdown,
    MercateamLogo,
  },
  props: {
    formTitle: {type: String, default: ""},
    readOnly: {type: Boolean, default: false},
    isOld: {type: Boolean, default: false},
    isOperationOngoing: {type: Boolean, default: false},
    toggleModal: {
      type: Function,
      default: () => null,
    },
  },
  setup(props) {
    const {t} = useI18n();

    const mainStore = useMainStore();
    const {
      userData,
      imports,
      simulation,
      simulations,
      clientParameters,
      isScheduling,
      theme,
      apiClient,
      team,
      teamSimulations,
      hasMercateam,
      isDevEnv,
    } = storeToRefs(mainStore);
    const {isOplitAdmin} = storeToRefs(useUserStore());

    const {
      selectedSimulation: storeSelectedSimulation,
      schedulingSimulations,
      teamSchedulingSimulations,
    } = storeToRefs(useSchedulingStore());

    const importTypesTogglerItems: {[key: string]: string[]} = {
      ofs: Array.from(
        /**
         * "import" key has to remain first for the code to work as intended
         * replace all importTypesTogglerItems.ofs[0] otherwise
         */
        ["import", "scheduling_simulation"],
        (key: string): string => t(`Simulation.Form.${key}`),
      ),
    };

    const step = ref(1);
    const currentSimulation = ref<Simulation>({});
    const selectedImports = ref<Record<string, {id: string}>>({});
    const selectedSimulation = ref<Simulation>({
      id: FORM_SIMULATIONS_SELECT_EMPTY,
    });
    const importTypes = ref<string[]>([]);
    const importIds = ref<Record<string, unknown[]>>({});
    const importsPlaceholder = ref<Record<string, unknown>>({});
    const isLoadingImports = ref(false);
    const mercaAbsences = ref(false);
    // see explanations in handleImportTypesTogglerInput
    const importTypesTogglerValues = ref({ofs: importTypesTogglerItems.ofs[0]});
    const shouldSimulationBeFloating = ref(
      !!clientParameters.value?.unique_scheduling_simulation,
    );
    const dateText = ref("");

    const simulationsList = computed(() => {
      const filteredSimulations = isScheduling.value
        ? schedulingSimulations.value
        : teamSimulations.value;
      const sortedSimulations = _.orderBy(
        filteredSimulations,
        (o) => parseDate(o.created_at),
        "desc",
      );
      return sortedSimulations;
    });

    const hasForceToday = computed(() => !!currentSimulation.value?.forceToday);
    const hasNoImports = computed(() => {
      return (
        (team.value?.floating_simulation ??
          clientParameters.value?.floating_simulation) &&
        !currentSimulation.value.forceToday &&
        (currentSimulation.value.import_ids?.every((x: any) => !x.id) ||
          !importTypes.value.some((x: string) => selectedImports.value[x]?.id))
      );
    });
    const canSimulationFloat = computed(
      () =>
        team.value?.floating_simulation ??
        clientParameters.value?.floating_simulation,
    );
    const isFloatingSimulation = computed(() => {
      return (
        canSimulationFloat.value &&
        shouldSimulationBeFloating.value &&
        (!hasForceToday.value || isScheduling.value) &&
        hasNoImports.value
      );
    });

    function loadImportIds(imports: any = []) {
      if (!imports?.length) return;
      const finishedImports = imports.filter((i) => i.status === "inserted");
      const teamImports = finishedImports.filter(
        (x: any) =>
          !x.teams?.length || x.teams.some((t: any) => t.id === team.value?.id),
      );
      const scopedImports = isScheduling.value ? finishedImports : teamImports;
      const sortedImports = _.orderBy(
        scopedImports,
        (o: any) => parseDate(o?.date),
        "desc",
      );
      const duplicatedImports = sortedImports.reduce((acc: any, curr: any) => {
        (curr.data || []).forEach((datum: string) => {
          acc.push({...curr, datum});
        });
        return acc;
      }, []);
      const tempImportIds = _.groupBy(duplicatedImports, (o: any) =>
        _.get(o, "datum"),
      );
      if (canSimulationFloat.value) {
        const floatImports = {};
        Object.keys(tempImportIds).forEach((id: string) => {
          tempImportIds[id].unshift({id: undefined});
          floatImports[id] = tempImportIds[id];
        });

        return floatImports;
      }
      return tempImportIds;
    }

    async function loadSimulation(simulation: any) {
      currentSimulation.value = {...simulation};
      dateText.value = periodeToText(
        currentSimulation.value?.forceToday ||
          moment().format(DATE_DEFAULT_FORMAT),
      );

      if (
        (!hasForceToday.value || isScheduling.value) &&
        hasNoImports.value &&
        canSimulationFloat.value
      )
        shouldSimulationBeFloating.value = true;

      if (
        canSimulationFloat.value &&
        currentSimulation.value.import_ids?.every((x: any) => !x.id)
      ) {
        const simulation_id = currentSimulation.value.id;
        const imports = (await apiClient.value.importIdFn(
          simulation_id,
          [],
          true,
        )) as {import_type}[];
        imports.forEach(({import_type}) => {
          selectedImports.value[import_type] = {id: undefined};
        });
        return;
      }
      if (!currentSimulation.value.import_ids?.length) return;
      const tempImports = currentSimulation.value.import_ids;

      tempImports.forEach((importEl: any) => {
        // determine whether the import is related to a list of simulation or not
        const arrayToSearchFrom = importEl.from_simu
          ? teamSchedulingSimulations.value
          : importIds.value[importEl.collection] || [];

        selectedImports.value[importEl.collection] = arrayToSearchFrom.find(
          (el: any) => el.id === importEl.id,
        ) as {id: string};
      });
    }

    async function _loadImportsData(imports: any) {
      isLoadingImports.value = true;
      const {client_id} = userData.value || {};
      const filteredImports = imports.filter(
        (x: any) => x.client_id === client_id && x.status === "inserted",
      ); //double safety here
      const [tempImportTypes, tempImportIds] = await Promise.all([
        getImportTypesFromImports(filteredImports),
        loadImportIds(filteredImports),
      ]);
      importTypes.value = tempImportTypes;
      importIds.value = tempImportIds;
      if (props.isOld) {
        if (!isScheduling.value) loadSimulation(simulation.value);
        else loadSimulation(storeSelectedSimulation.value);
      }
      isLoadingImports.value = false;
    }

    const loadImportsData = _.debounce(_loadImportsData, 300);

    watch(
      () => [simulation.value, storeSelectedSimulation.value],
      ([pdpSimulation, schedulingSimulation]) => {
        const consideredSimulation = isScheduling.value
          ? schedulingSimulation
          : pdpSimulation;

        if (!props.isOld || !consideredSimulation?.id) return;
        step.value = 2;
        loadImportsData(imports.value);

        if (isScheduling.value) return;

        // "from_simu" is set to true when data coming from a simulation list was saved
        if (consideredSimulation.import_ids?.[0]?.from_simu)
          importTypesTogglerValues.value.ofs = importTypesTogglerItems.ofs[1];
      },
      {immediate: true},
    );

    return {
      userData,
      imports,
      simulation,
      simulations,
      clientParameters,
      isScheduling,
      theme,
      apiClient,
      team,
      teamSimulations,
      hasMercateam,
      isDevEnv,
      isOplitAdmin,
      storeSelectedSimulation,
      schedulingSimulations,
      teamSchedulingSimulations,
      step,
      currentSimulation,
      selectedImports,
      selectedSimulation,
      importTypes,
      importIds,
      importsPlaceholder,
      isLoadingImports,
      mercaAbsences,
      importTypesTogglerValues,
      importTypesTogglerItems,
      shouldSimulationBeFloating,
      dateText,
      simulationsList,
      loadImportsData,
      hasForceToday,
      canSimulationFloat,
      isFloatingSimulation,
      loadSimulation,
      loadImportIds,
      TEST_IDS,
    };
  },
  computed: {
    isSimulationImportedLoad(): boolean {
      return (
        this.importTypesTogglerValues.ofs ===
        this.$t(`Simulation.Form.scheduling_simulation`)
      );
    },
    isValidStepOne(): boolean {
      const {
        canSimulationFloat,
        shouldSimulationBeFloating,
        isScheduling,
        currentSimulation,
      } = this;
      return Boolean(
        (canSimulationFloat && shouldSimulationBeFloating) || isScheduling
          ? currentSimulation.name
          : currentSimulation.name && currentSimulation.forceToday,
      );
    },
    isValidForm(): boolean {
      const {
        canSimulationFloat,
        isValidStepOne,
        isFloatingSimulation,
        importTypes,
        selectedImports,
        hasForceToday,
      } = this;
      if (!isValidStepOne) return false;
      if (!canSimulationFloat || !isFloatingSimulation)
        return importTypes.every((x: string) => selectedImports[x]?.id);
      if (importTypes.some((x: string) => selectedImports[x]?.id))
        return hasForceToday;

      return isFloatingSimulation;
    },
    isDisabledConfirmBtn(): boolean {
      const {step, isValidStepOne, isValidForm} = this;
      switch (step) {
        case 1:
          return !isValidStepOne;
        case 2:
          return !isValidForm;
        default:
          return true;
      }
    },
    shouldDisplayStartDate() {
      const {isFloatingSimulation, isScheduling, currentSimulation} = this;
      return (
        !isFloatingSimulation &&
        (!isScheduling || currentSimulation.status === "archived")
      );
    },
    isReadOnly(): boolean {
      const {readOnly, currentSimulation} = this;
      return currentSimulation.status === "archived" || readOnly;
    },
    computedSimulations(): Simulation[] {
      const simulations = this.isScheduling
        ? this.teamSchedulingSimulations
        : this.teamSimulations;
      return [{id: FORM_SIMULATIONS_SELECT_EMPTY}, ...simulations];
    },
  },
  watch: {
    imports: {
      immediate: true,
      handler: function (val: any) {
        if (!val?.length) return;
        this.loadImportsData(val);
      },
    },
    computedSimulations: {
      immediate: true,
      handler: function (val: any, oldVal: any) {
        if (oldVal?.length > 1 || val?.length <= 1) return;
        if (this.selectedSimulation?.id !== FORM_SIMULATIONS_SELECT_EMPTY)
          return;
        // if (this.userData?.client_id === "bkEfcYyUTImjXHU3xtsO") return;
        this.selectedSimulation = this.computedSimulations.find(
          (x: any) =>
            x.status !== "removed" && x.id !== FORM_SIMULATIONS_SELECT_EMPTY,
        );
      },
    },
  },
  methods: {
    handleStepper(index: number): void {
      if (this.isOld) return;
      if (this.isValidStepOne) this.step = index;
    },
    handleDateChange(v: string) {
      this.currentSimulation = {
        ...this.currentSimulation,
        forceToday: v,
        dateMin: v,
      };
      this.dateText = periodeToText(v ? v : moment().format("YYYY-MM-DD"));
    },
    handleTypeChange(v: boolean) {
      const {selectedImports, dateText, isScheduling} = this;

      this.handleDateChange(v || isScheduling ? null : dateText);
      Object.keys(selectedImports).forEach(
        (type: string) => (this.selectedImports[type] = undefined),
      );

      this.shouldSimulationBeFloating = v;
    },
    handleValidateForm() {
      const {
        currentSimulation,
        selectedImports,
        selectedSimulation,
        mercaAbsences,
        hasMercateam,
        isSimulationImportedLoad,
      } = this;

      if (isSimulationImportedLoad)
        _.set(selectedImports.ofs, "from_simu", true);

      this.$emit("handle-validate", {
        currentSimulation,
        selectedImports,
        selectedSimulation,
        mercaAbsences: hasMercateam && mercaAbsences,
      });
    },
    async hacky_remigrateEvents() {
      const {
        selectedSimulation,
        userData,
        simulation,
        team,
        isScheduling,
        simulations,
      } = this;

      if (!selectedSimulation?.created_at) return;
      this.apiClient
        .migrateEvents({
          simulation_id: simulation.id,
          simulation_start_date: simulation.forceToday,
          simulations: isScheduling ? this.schedulingSimulations : simulations,
          team,
          selected_simulation: selectedSimulation,
          client_id: userData.client_id,
          is_scheduling: isScheduling,
        })
        .then((d: any) => loggerHelper.log(d));
    },
    /**
     * for customers that have pdp & ordo activated,
     * we should display a toggler in the PDP module
     * between pdp imports & ordo simulations for the "ofs" import type
     */
    showEventualToggler(importType: string): boolean {
      return (
        this.userData?.has_production_planning &&
        this.userData?.has_scheduling &&
        !this.isScheduling &&
        ["ofs"].includes(importType)
      );
    },
    /**
     * since the merge PDP/ordo users whom have both modules activated are able to use PDP simulations in ordo and vice-versa
     * for this reason and the fact that the import types are rendered inside a v-for, we created state objects used to store
     * - the value for a given import type (importTypesTogglerValues)
     * - the list of values available for selection for this import type (importTypesTogglerItems)
     * the display of such lists/toggler is handled by this.showEventualToggler method
     */
    handleImportTypesTogglerInput(key: string, importType: string): void {
      this.importTypesTogglerValues[importType] = key;
    },
    onConfirmBtnClick(): void {
      if (this.step === 1) this.step += 1;
      else this.handleValidateForm();
    },
    getImportTypeSelectItems(importType: string): any[] {
      const {
        importTypesTogglerValues,
        importTypesTogglerItems,
        importIds,
        teamSchedulingSimulations,
      } = this;
      let returnedArr = [];
      switch (importType) {
        case "ofs":
          returnedArr =
            importTypesTogglerValues.ofs === importTypesTogglerItems.ofs[0]
              ? importIds[importType] || []
              : teamSchedulingSimulations;
          break;
        default:
          returnedArr = importIds[importType] || [];
          break;
      }
      if (!returnedArr) returnedArr = [];
      return returnedArr.filter((x: any) => x.id !== undefined);
    },
    getSelectedImportItem(importType: string): any {
      return this.getImportTypeSelectItems(importType).find(
        ({id}: {id: string}) => {
          const selectedImportId =
            this.selectedImports[importType]?.id ||
            this.selectedImports[importType];
          return id === selectedImportId;
        },
      );
    },
  },
});
</script>

<style scoped lang="scss">
.radio-group {
  margin: 0;
  padding: 0;

  label {
    color: rgb(var(--v-theme-newMainText));
  }
}

.v-list-item {
  padding: 2px 4px !important;
}
</style>

<style lang="scss">
@import "@/scss/constants.scss";
@import "@/scss/stepper.scss";

.import-type {
  width: 100%;
  &-file,
  &-name {
    width: 145px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: auto;
  }
  &-simu {
    margin: 0 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: auto;
  }
  .feather {
    min-width: 24px;
  }
  .v-chip__content {
    color: rgb(var(--v-theme-newMainText));
  }
}
.new-simulation {
  height: 100%;
  &-form {
    border: 1px solid rgb(var(--v-theme-newHover));

    .datepicker {
      button {
        width: 100%;
        background-color: rgb(var(--v-theme-newSubBackground));
        justify-content: flex-start;
        border-radius: 4px !important;
        margin: 0 !important;
        height: 40px !important;
        font-size: 16px;
        .feather {
          color: rgb(var(--v-theme-newSubText));
        }
      }
    }
  }
}

.new-simulation-form {
  border: 1px solid rgb(var(--v-theme-newSelected));

  & .v-stepper {
    overflow: hidden;
    box-shadow: none;
    padding-bottom: var(--g-vertical-spacing);

    & .v-stepper__step--active {
      & .v-stepper__label {
        color: rgb(var(--v-theme-newPrimaryRegular));
      }

      & + .stepper-content.v-stepper__content {
        /* overriding current step padding to match global definition */
        padding: var(--g-vertical-spacing);
        // displaying the left border for the last step if active
        border-left: 1px solid rgb(0, 0, 0);
        margin-left: 44px;
      }
    }

    & .stepper-content {
      /* 44px is default left margin for .v-stepper__content */
      max-width: calc(100% - 44px);

      &.v-stepper__content {
        /* overriding completed step padding to match global definition */
        padding: calc(var(--g-vertical-spacing) / 2) var(--g-vertical-spacing);
      }

      & > .v-stepper__wrapper {
        display: flex;
        flex-direction: column;
        gap: var(--g-vertical-spacing);
      }
    }

    &.is-last-step {
      overflow: visible;
      animation: 7s delay-overflow;
    }
  }
  .v-menu__content {
    margin: 45px 0;
  }
  .v-select-list {
    background-color: rgb(var(--v-theme-newLayerBackground));
  }
  .v-stepper--vertical {
    .v-stepper__content {
      margin: -8px -16px -16px 44px;
      padding: 8px 48px 0px 23px;

      &:not(:last-child) {
        border-color: rgb(var(--v-theme-newMainText)) !important;
      }
    }
    .v-stepper__step {
      padding: 24px 32px 16px;
      &.v-stepper__step--inactive {
        .v-stepper__step__step {
          color: rgb(var(--v-theme-noir));
        }
      }
    }
  }
  .last-step .v-stepper__wrapper {
    overflow: visible;
  }
}

@keyframes delay-overflow {
  from {
    overflow: hidden;
  }
}
</style>
