const DATE_STOCKS_DEMANDS_FORMAT = "DD.MM.YYYY";
const DATE_STOCKS_GROUPED_DEMANDS_FORMAT = "MMMM YY";
const DEMAND_LOWEST_LEVEL_FIELD_NAME = "article_id";
const STOCKS_LOWEST_LEVEL_FIELD_NAME = "secteur_id";
const DEFAULT_SORT_CRITERION_DEMAND = "day_date";
const DEFAULT_SORT_CRITERION_PRODUCT: string = STOCKS_LOWEST_LEVEL_FIELD_NAME;

const STOCKS_GRAPH_OBJECTIVE_OBJECT = "objectiveObject";
const STOCKS_GRAPH_DEMANDS_OBJECT = "demandsObject";
const STOCKS_GRAPH_STOCKS_OBJECT = "stocksObject";

const STOCKS_TABLE_VIEWS = [
  "famille",
  "article_id",
  STOCKS_LOWEST_LEVEL_FIELD_NAME,
];

const STOCKS_DEMAND_GROUP_BY_FIELDS = [
  "customer",
  "famille",
  "month_date",
  "order_number",
];
const STOCKS_DEMANDS_FIRST_COLUMN_FIELDS = [
  ...STOCKS_DEMAND_GROUP_BY_FIELDS,
  "article_id",
];
const STOCKS_GRAPH_OBJECTS_KEYS = [
  STOCKS_GRAPH_OBJECTIVE_OBJECT,
  STOCKS_GRAPH_DEMANDS_OBJECT,
  STOCKS_GRAPH_STOCKS_OBJECT,
];

const STOCKS_PROD_OBJ_TYPES = {
  DEMAND: "demand",
  TARGET_STOCK: "target_stock",
  MANUAL: "manual",
};

/**
 * this array is also used in the back (eponymous variable name)
 * NB: the `wip` and `final_wip` keys are voluntarily absent from the back because
 * their weight is calculated through compounding unlike the other keys
 */
const STOCKS_VALUATION_WEIGHTED_FIELDS = [
  "demande",
  "initial_stock",
  "objective",
  "final_stock",
  "wip",
  "final_wip",
];

const STOCKS_VALUATION_FIELDS_PREFIX = "weighted_";

const STOCKS_DEFAULT_LOAD_QUANTITY_FIELD = "quantite_of";

export {
  DEFAULT_SORT_CRITERION_DEMAND,
  DEFAULT_SORT_CRITERION_PRODUCT,
  STOCKS_GRAPH_DEMANDS_OBJECT,
  STOCKS_GRAPH_OBJECTIVE_OBJECT,
  STOCKS_GRAPH_STOCKS_OBJECT,
  STOCKS_GRAPH_OBJECTS_KEYS,
  DATE_STOCKS_DEMANDS_FORMAT,
  STOCKS_TABLE_VIEWS,
  STOCKS_PROD_OBJ_TYPES,
  STOCKS_DEMAND_GROUP_BY_FIELDS,
  STOCKS_DEMANDS_FIRST_COLUMN_FIELDS,
  DATE_STOCKS_GROUPED_DEMANDS_FORMAT,
  DEMAND_LOWEST_LEVEL_FIELD_NAME,
  STOCKS_LOWEST_LEVEL_FIELD_NAME,
  STOCKS_VALUATION_WEIGHTED_FIELDS,
  STOCKS_VALUATION_FIELDS_PREFIX,
  STOCKS_DEFAULT_LOAD_QUANTITY_FIELD,
};
